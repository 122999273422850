import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import { Button, Modal } from "antd"
import ImageGallery from "../components/ImageGallery"

const Boucles_d_oreilles = () => {

//Hooks
const [dataContact, setDataContact] = useState(false)

//Modal
const [showModalContact, setShowModalContact] = useState(false)

const showModalContactForm = (value_i) => {
  setShowModalContact(true)
  setDataContact(value_i)
}

const handleContactFormOk = e => {
  setShowModalContact(false)
}

const handleContactFormCancel = e => {
  setShowModalContact(false)
}

return (
  <>
  <Layout>
    <SEO title="Boucles D'Oreilles" />

    <div className="slideshow">
      <div className="usp">
        <div className="usp-txt-header">Boucles d'oreilles</div>
        {/*<div  className="usp-txt" style={{fontSize: '2.6em'}}>Classique ou originale, retrouvez un large choix dans nos boutiques.</div>*/}
      </div>
    </div>
    <div className="border-gold-bottom"></div>

    <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Boucles d'oreilles</h2>



    <h3 className="h3-mobile" style={{textAlign: 'center'}}>Classique ou originale, retrouvez un large choix dans nos boutiques.</h3>

    <p className="p-2 p-font mobile-display" style={{textAlign: 'center'}}>Classique ou originale, retrouvez un large choix dans nos boutiques.</p>

    <section>
      <ImageGallery id="bouclesoreilles" />

      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Boucles d\'oreilles') }>Nous contacter</Button></div>
      <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
        visible={ showModalContact }
        onOk={ handleContactFormOk }
        onCancel={ handleContactFormCancel }
        okButtonProps={{ style: { display: 'none' } }}
      >

        <ContactForm page={dataContact} />

      </Modal>

    </section>

  </Layout>
  </>
)
}

export default Boucles_d_oreilles

